import React from "react";
import { Container, Breadcrumb, Row, Col, Button} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Post } from "../../components/Core";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';
import ReactStars from "react-rating-stars-component";
import DataTable from '../../components/data/banque/avisbanque'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons'

function avis() {

	const banque = 'Fortuneo Banque'
	const go = `/go/?go=${banque}`
	
	const star1 = {size: 22,value: 4.2,color: "grey",activeColor: "#ffc107",edit: false,};
	const star2 = {size: 22,value: 4.1,color: "grey",activeColor: "#ffc107",edit: false,};
	const star3 = {size: 22,value: 4,color: "grey",activeColor: "#ffc107",edit: false,};
	const star4 = {size: 22,value: 4,color: "grey",activeColor: "#ffc107",edit: false,};
	const star5 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,};
	const star6 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,}; 
	

    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Avis Fortuneo Banque: à lire absolument avant d'ouvrir un compte</title>
                <meta name="description" content="
              Cette banque est-elle réellement gratuite ? Avis détaillé de la banque Fortuneo avec notations, présentation des services et tous les frais bancaires." />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item> <Link to={`/banque/`} style={{color: 'black'}}>Avis Banques</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Avis Fortuneo Banque</Breadcrumb.Item>
      	 </Breadcrumb>

	
	<Row>
		<Col sm={9}>  <h1 id='avish1' style={{color: 'rgb(41, 49, 61)'}}>Avis Fortuneo Banque</h1>
          <h2 id='avish2' style={{marginLeft: 0, marginBottom: 25, color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>La banque Fortuneo est recommandée par 9 de ses clients sur 10 (baromètre CSA). C’est une offre complète qui vient avec conditions d’ouvertures. L’Avis Fortuneo ci-dessous :</h2>
	
 <a href={go} target='_blank' rel="noreferrer">
                <Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', borderColor: 'rgb(240 0 87)', color: 'white'}}>Voir l'offre <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
                </a>

		  
		  </Col>
	
		<Col sm={3} id='logoAvis'>
		<a href={go} target='_blank' rel="noreferrer">
		<StaticImage
		  	src='../../images/fortuneo-banque-logo.png'
			width={250}
			alt='logo fortuneo banque'
			placeholder='tracedSVG'
			/>
		</a>
		</Col>
	</Row>

		 
<hr></hr>
<Row>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Compte bancaire</p></Col>
			<Col> <ReactStars {...star1} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Carte bancaire</p></Col>
			<Col><ReactStars {...star2} /></Col>
		</Row>
	</Col>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Tarifs</p></Col>
			<Col><ReactStars {...star3} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Application</p></Col>
			<Col><ReactStars {...star4} /></Col>
		</Row>
	</Col>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Comptabilité</p></Col>
			<Col><ReactStars {...star5} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Gestion</p></Col>
			<Col><ReactStars {...star6} /></Col>
		</Row>
	</Col>
</Row>
<hr></hr>
<Post>



<h2>Offre Fortuneo Banque</h2>
<p>L’offre de bienvenue de Fortuneo est un bonus de 80€.
</p>
<p>Cette offre est disponible pour une première ouverture d’un compte bancaire et sera activé par 5 paiements par carte.</p>




<iframe id='videoYT'src="https://www.youtube.com/embed/JzXoATTBi0o" title="avis Fortuneo Banque" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


<hr></hr>

<h2>Les cartes bancaires Fortuneo Banque sont-elles gratuites ?</h2>



<div style={{marginLeft: -5}}>
<DataTable row={[14,15,16]} what='cartesbancaires' id='postTable'/>
<DataTable row={[14,15,16]} what='cartesbancaires' id='mobileTable'/>

</div>

<h3>Mastercard Fosfo</h3>
<p>Les services gratuits de la carte mastercard Fosfo sont :</p>
<ul>
	<li>Paiements et retraits en Euro gratuits</li>
	<li>Aucun frais sur les paiements à l’international</li>
	<li>Aucun frais sur les retraits par carte bancaire partout dans le monde</li>
	<li>Aucune limite de transaction (limite de plafond de votre carte bancaire)</li>
	<li>Cartes virtuelles</li>

</ul>
<h3>Mastercard Gold</h3>
<p>Les services gratuits de la carte Mastercard Gold sont :</p>
<ul>
<li>Paiements et retraits en Euro gratuits</li>
<li>Aucun frais sur les paiements à l’international</li>
<li>Aucun frais sur les retraits par carte bancaire partout dans le monde</li>
<li>Aucune limite de transaction (limite de plafond de votre carte bancaire)</li>
<li>Cartes virtuelles</li>
<li>Garanties d’assistance et d’assurance renforcées pour vos loisirs et vos voyages.</li>
<li>Plafonds de retrait et de paiement élevés</li>
</ul>
<h3>Carte World Elite</h3>
<p>
Les services gratuits de la carte Mastercard World Elite sont :
</p>
<ul>
	<li>Paiements et retraits en Euro gratuits</li>
	<li>Aucun frais sur les paiements à l’international</li>
	<li>Aucun frais sur les retraits par carte bancaire partout dans le monde</li>
	<li>Aucune limite de transaction (limite de plafond de votre carte bancaire)</li>
	<li>Cartes virtuelles</li>
	<li>Garanties d’assistance et d’assurance renforcées pour vos loisirs et vos voyages</li>
	<li>Plafonds de retrait et de paiement élevés</li>
	<li>Service de conciergerie à votre service 24h/24</li>
	<li>Services d’exception en France et à l’étranger</li>
	<li>Offres privilégiées</li>
</ul>


<div style={{maxWidth: 800, margin: 'auto'}}>
			<StaticImage
		  	src='../../images/fortuneo-banque-site.png'
			width={800}
			alt='site fortuneo banque'
			placeholder='tracedSVG'
			id='pickky'
			/>
			</div>




			<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
			<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Fortuneo Banque <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

	<h2>Avis Fortuneo et le mobile</h2>
<h3>Fortuneo utilise l’open banking pour centraliser vos comptes</h3>
<p>
Fortuneo Banque donne la possibilité d’ajouter des comptes externes à votre application mobile. Cette option permet de visualiser plus simplement et en un coup d’oeil l’état de votre budget. Vous pouvez par exemple voir votre compte courant Fortuneo Banque à côté de votre livret A de votre banque d’enfance ainsi que votre compte chèque d’une banque traditionnelle.
</p><h3>
Paiement mobile Fortuneo</h3>
<p>
Fortuneo permet le paiement mobile avec Apple Pay, Google Pay, Samsung Pay. Vous pourrez aussi payé avec une sélection de montres connectés : Apple Watch, Garmin et Fitbit.
</p>
<h2>
Cartes virtuelles Fortuneo – Protéger vos transactions bancaires sur internet</h2>

<p>
Payer sur internet peut parfois être stressant. Les enseignes en ligne sont de moins en moins liées à des boutiques réelles et il n’est pas toujours facile de vérifier la fiabilité des vendeurs. Fortuneo Banque propose des cartes virtuelles configurables qui vous permettront de choisir un montant ainsi qu’une durée d’activation de votre numéro de carte.
</p><p>
Chacun de vos paiement sur internet devient alors complètement indépendant. Si par malchance vous avez à faire à un marchand frauduleux, votre risque sera limité à une seule transaction.
</p>

<h3>Le chéquier Fortuneo</h3>
<p>Si le chéquier Fortuneo est gratuit, sa réception n’est pas pour autant automatique. Vous devrez commander votre chéquier en vous connectant à votre espace client.
</p><p>
Les dépôts de chèques sont gratuits. Chaque chèque devra être envoyé accompagné d’un bordereau de remise de chèques à l’adresse suivante :
</p>
<p>
Fortuneo – TSA 70406 – 35 574 CHANTEPIE CEDEX.</p>
<p>
Les chèques de banques sont aussi gratuits en envoi simple mais vous coûteront 5€ en envoi recommandé.
</p>
<hr></hr>

<h2>Frais Bancaire Avis Fortuneo Banque</h2>
<h3>Virements sortants</h3>
<DataTable row={[14,15,16]} what='virementSortants' id='postTable'/>
<DataTable row={[14,15,16]} what='virementSortants' id='mobileTable'/>

<h3>Virements entrants</h3>
<DataTable row={[14,15,16]} what='virementEntrants' id='postTable'/>
<DataTable row={[14,15,16]} what='virementEntrants' id='mobileTable'/>

<h3>Découverts</h3>
<DataTable row={[14,15,16]} what='decouverts' id='postTable'/>
<DataTable row={[14,15,16]} what='decouverts' id='mobileTable'/>

<h3>Irrégularités et incidents</h3>
<DataTable row={[14,15,16]} what='irregularites' id='postTable'/>
<DataTable row={[14,15,16]} what='irregularites' id='mobileTable'/>

<h2>Fortuneo Banque Pour Les Voyageurs</h2>
<h3>Retraits et paiements à l’étranger</h3>

<DataTable row={[14,15,16]} what='retraits' id='postTable'/>
<DataTable row={[14,15,16]} what='retraits' id='mobileTable'/>

<h3>Assurances</h3>
<DataTable row={[14,15,16]} what='assurances' id='postTable'/>
<DataTable row={[14,15,16]} what='assurances' id='mobileTable'/>

<h2>Service client Fortuneo</h2>
<p>
Vous pourrez joindre Fortuneo durant ses heures d’ouverture du Lundi au Vendredi de 8h à 20h et le Samedi de 9h à 18h.
</p>
<ul>
	<li>Email et chat</li>
	<li>Telephone : 0800 800 040 (appel gratuit)</li>
	<li>Twitter : <a href='https://twitter.com/fortuneo'>Fortuneo Banque sur Twitter</a></li>
	<li>Facebook: <a href='https://www.facebook.com/fortuneo.banque/'>Foruneo Banque sur Facebook</a></li>
</ul>
<h2>
Avis Fortuneo</h2>
<p>
Fortuneo fait partie du groupe Crédit Mutuel Arkéa et dispose actuellement de 740 000 clients actifs.
</p><p>
Si la domiciliation du salaire n’est pas obligatoire chez Fortuneo Banque, ce compte à un véritable sens si il devient votre compte principale. Notre avis Fortuneo est que l’offre est complète et peut facilement remplacer votre banque traditionnelle. L’offre est encore plus forte si vous achetez et vendez des actions en Bourse.
</p>
<p>
Ce que dit le web :
</p>
<p>
Apple Store : note utilisateurs 4.4 / 5<br></br>
Google Play : note utilisateurs 4.3 / 5</p>

<p>
9 clients sur 10 recommandent Fortunéo (91 % des clients ayant un compte bancaire chez Fortuneo, baromètre mené par CSA, février 2019)
</p><p>
Fortuneo en 1ère place du classement indépendant des meilleures banques (Bankin’, n°1 ex aequo, septembre 2019).
</p><p>
Fortuneo-Vie, récompensé au Grand Prix de l’assurance-vie 2019 dans la catégorie « contrat multisupports » (Mieux Vivre Votre Argent, avril 2019).
</p><p>
Fortuneo récompensée par le Label d’Excellence pour ses compte-titres Optimum et 0 Courtage (Les Dossiers de l’ Épargne, septembre 2018).
</p>

<h3>Avantages</h3>

<ul>
	<li>Fortuneo ne le met pas forcément en avant mais sa grande force est l’international avec la gratuité des transactions hors de la zone Euro pour sa carte gold.</li>
	<li>L’offre est très complète avec livrets d’épargne et compte titres</li>
	<li>Application complète avec connection de vos autres comptes en banque</li>
	<li>Site internet très bien alimenté avec beaucoup de questions / réponse</li>
	<li>Offre facilité de caisse rare chez les banques en ligne</li>
</ul>


<h3>Inconvénients</h3>
<ul>
	<li>Les conditions d’ouverture peuvent paraître contraignantes</li>
	<li>Versement initial élevé</li>
	<li>Dépôts de chèque doit se faire par courrier</li>
</ul>


<h2>Conditions d’ouverture d’un compte Fortuneo</h2>
<p>
La gratuité du compte Fortuneo dépend de certaines conditions :
</p><p>
La Mastercard Fosfo ne nécessite qu’une transaction par mois sans conditions de revenu.
</p><p>
Pour la Mastercard Gold vous devez aussi avoir des revenus net de plus de 1800€/mois ou plus de 10 000€ d’épargne.
</p><p>
Finalement, pour gagner accès à la World Elite vous devez montrer des revenus net de 4000€/mois et faire un versement mensuel de 4000€ sur votre compte.
</p>
<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Je veux en savoir + <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

	<h2>Ouvrir un compte Fortuneo Banque</h2>
	<p>
L’ouverture d’un compte Fortuneo Banque se fait en 4 étapes :</p>

<p>1 – Remplissez le formulaire sur la <a href={go}>page suivante</a></p>
<p>
2 – Signez électroniquement votre contrat par SMS</p>
<p>
3 – Envoyez les pièces justificatives suivantes :</p>
<p>
Un justificatif d’identité en cours de validité :
</p>
<ul>
	<li>Carte d’identité nationale recto / verso</li>
	<li>Passport (2 premières pages)</li>
	<li>Titre de séjour recto verso</li>
</ul>

<p>
Une avis d’imposition ou un avis de situation déclarative à l’impôt sur le revenue de moins de 12 mois</p>

<p>
4 – Activer votre compte en envoyant votre versement initial</p>
<p>
Vous pouvez envoyer votre 1er versement initial de 2 manières :</p>
<ul>
	<li>Par virement en accompagnant dans un premier temps votre inscription d’un relevé d’identité bancaire (RIB) d’un compte en France à votre nom</li>
	<li>Par courrier, en envoyant un chèque bancaire d’un montant de 300 € à l’adresse suivante :</li>
</ul>
<p>
Fortuneo<br></br>
Service Clients<br></br>
Libre Réponse 26 157<br></br>
29 809 Brest Cedex 09
</p>

<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Ouvrir un compte <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

		</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default avis
